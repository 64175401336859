import React from 'react';
import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';
import { PortalLayout } from 'front-components/src/components';
import { AdminMenu } from '../components/asside-menu/asside-menu';
import { EditResellerContent } from '../content/reseller/edit-reseller';
import { PortalHeader } from '../components/portal-header/portal-header';

function ResellerDetailsPage({ params }) {
  const asside = <AdminMenu />;

  return (
    <main>
      <title>Edit Resellers Page</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        <EditResellerContent resellerId={params.name} />
      </PortalLayout>
    </main>
  );
}

export default ResellerDetailsPage;
